/* eslint-disable */
import { useRouter } from "next/router";
import React, { memo, useContext, useEffect, useState } from "react";

const CityLandingComponent = dynamic(() => import("@/components/city-landing"));

import HeaderFooterContext from "@/context/HeaderFooterContext";
import { getCityLandingInfo, getRightUnitInfo, getVehicleLandingData } from "@/services/home";

import { BRAND_NAME_EN, BRAND_NAME_FR } from "@/utils/constants";
import dynamic from "next/dynamic";
import MapCordinateContext from "@/context/MapCordinateContext";
import PageNotFound from "@/pages/404";

const CityComponent = (props: any) => {
  const headerFooterData = useContext(HeaderFooterContext);
  const { headerFooter } = headerFooterData;
  const {vehicleLandingPage} = props ;

  const [unitsInfo, setUnitsInfo] = useState([]);
  const [cityInfo, setCityInfo] = useState<any>();
  const [brandCta, setBrandCta] = useState(null);
  const mapCords = useContext(MapCordinateContext);
  const [ordinate, setOrdinate] = useState<any>();
  const { locale }: any = useRouter();

  const isContext = !!headerFooter[0];
  const [err, setErr] = useState(false);

  const getContextData = async () => {
    
    const brands = props?.brandData?.data;
    const Cta =
      brands &&
      brands[0] &&
      brands[0].attributes &&
      brands[0].attributes.CTA_Section;
    setBrandCta(Cta);
    headerFooterData.setHeaderFooterData(brands);
  };
  useEffect(() => {
    getContextData();
  }, [isContext, locale]);
  useEffect(() => {
    const obj = {
      lat: parseFloat(cityInfo?.attributes?.Latitude),
      lng: parseFloat(cityInfo?.attributes?.Longitude),
    };
    setOrdinate(obj);
    return () => {
      mapCords.setLatLogCity({});
    };
  }, []);

  const getFacilityData = async () => {
    let defaultBrand = BRAND_NAME_EN;
    let slug = props?.city;
    if (locale === "fr") {
      defaultBrand = BRAND_NAME_FR;
      slug = `${props?.city}-fr`;
    }
    const cityResult: any = await Promise.all([
      getRightUnitInfo(defaultBrand, locale),
      vehicleLandingPage ? getVehicleLandingData(slug, defaultBrand, locale) : getCityLandingInfo(slug, defaultBrand, locale)
    ]);
    setUnitsInfo(cityResult[0]?.data);
    setCityInfo(cityResult[1]?.data?.data[0]);

    const newArray = [
      {
        lan: cityResult[1]?.data?.data[0]?.attributes?.locale,
        slugValue: cityResult[1]?.data?.data[0]?.attributes?.Slug,
      },
      {
        lan: cityResult[1]?.data?.data[0]?.attributes?.localizations?.data[0]?.attributes
          ?.locale,
        slugValue:
        cityResult[1]?.data?.data[0]?.attributes?.localizations?.data[0]?.attributes
            ?.Slug,
      },
    ];
    if (newArray[0].lan) {
      sessionStorage.setItem("facCords", JSON.stringify(newArray));
    }

    
    if (cityResult[1]?.data?.data?.length === 0) {
      setErr(true);
    } else {
      setErr(false);
    }
  };

  useEffect(() => {
    getFacilityData();
  }, [locale, location]);
  return (
    <>
      {!err && cityInfo && brandCta && unitsInfo && (
        <CityLandingComponent
          cords={ordinate}
          cityLandingInfo={cityInfo}
          ourBrandsData={brandCta}
          bodyData={unitsInfo}
          hostUrl={props?.hostUrl}
          vehicleLandingPage={vehicleLandingPage}
        />
      )}
      {err && <PageNotFound />}
    </>
  );
};

export default memo(CityComponent, () => true);
